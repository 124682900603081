/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* Custom CSS */

/* For DATATABLE */
.table > :not(:last-child) > :last-child > * {
  border-bottom-color: white;
}
.dt-buttons {
  float: left;
}
table.dataTable tbody td {
  padding: 8px 10px;
  color: #666;
  vertical-align: middle;
  font-weight: 300;
}
.dataTables_wrapper .dataTables_filter input {
  border: 1px solid #ddd !important;
  border-radius: 0px !important;
}

/* Left Sidebar */
.am-sideleft-menu {
  padding: 10px 20px 10px 0px;
}
.am-sideleft-menu > .nav-item > .nav-link {
  padding-left: 20px;
}
.am-sideleft-menu > .nav-item > .nav-link.active {
  background-color: antiquewhite;
}

/* LeftSidebar icons sizing */
.am-sideleft-menu > .nav-item > .nav-link i:first-child {
  font-size: 17px;
}

/* Input field  */
.form-control,
.dataTables_filter input {
  font-size: 0.85rem;
  font-weight: 300;
}
label {
  font-weight: 300;
  color: #666;
}
